import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { VideoBlog } from "../../../components/video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";

const VidImg = require("../../../assets/img/integration/bamboohr/video_img_fs.png");

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");
const image1 = require("../../../assets/images/freshservice_blog/1_1.png");
const image2 = require("../../../assets/images/freshservice_blog/1.png");
const image3 = require("../../../assets/images/freshservice_blog/2.png");
const image4 = require("../../../assets/images/freshservice_blog/3_3.png");
const image5 = require("../../../assets/images/freshservice_blog/3.png");
const image6 = require("../../../assets/images/freshservice_blog/4.png");
const image7 = require("../../../assets/images/freshservice_blog/5.png");
const image8 = require("../../../assets/images/freshservice_blog/6.png");
const image9 = require("../../../assets/images/freshservice_blog/7.png");
const image10 = require("../../../assets/images/freshservice_blog/8.png");
const image11 = require("../../../assets/images/freshservice_blog/9.png");
const image12 = require("../../../assets/images/freshservice_blog/10.png");
const image13 = require("../../../assets/images/freshservice_blog/11.png");
const image14 = require("../../../assets/images/freshservice_blog/12.png");
const image15 = require("../../../assets/images/freshservice_blog/13.png");
const image16 = require("../../../assets/images/freshservice_blog/l_1.png");
const image17 = require("../../../assets/images/freshservice_blog/l_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  return (
    <>
      <TitleAndMetaTags
        title="Free Freshservice Chatbot Guide for your Business. Build Freshservice Chatbot for automated workplace support."
        description=" Build Freshservice Chatbot without any coding in minutes Workativ’s platform provides easy tools to quickly build your Freshservice chatbot and automate workplace support"
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={image1}
        schema={ldSchema}
      />
      <section className="market_main_wrapper chatbot-guide-blog-main-freshservice">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header">
                            The Ultimate Guide to Freshservice Chatbot
                          </h1>
                        </div>
                      </div>
                      <section className="table_contents_wrapper mb-3">
                        <h3 class="font-section-sub-header-small">
                          Table of contents
                        </h3>
                        <div className="table_contents_link font-section-normal-text-medium">
                          <AnchorLink offset={180} href="#section1">
                            1. What is Freshservice Chatbot?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section2">
                            2. How does Freshservice Chatbot works?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section3">
                            3. What is Freshservice Chatbot used for?
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section4">
                            4. Best Freshservice Chatbot platform
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section5">
                            5. Smarter AI-powered Freshservice automation with
                            Freshservice Chatbot
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section6">
                            6. Business benefits of Freshservice chatbot with
                            Workativ Assistant
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section7">
                            7. How to Build Freshservice Chatbot Without Without
                            coding
                          </AnchorLink>
                          <AnchorLink offset={180} href="#section8">
                            8. Conclusion
                          </AnchorLink>
                        </div>
                      </section>

                      {/* Header Section */}
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        <p class="font-section-normal-text line-height-2 mb-0">
                          No, this blog isn’t an Ad for Trivago. Apparently,
                          there’s not a single Trivago Guy GIF. Or JIF. Or
                          whatever.   Maybe you’re a CIO, an IT Admin, or maybe
                          even a help desk agent who’s looking to take some load
                          off by automating your Freshservice tasks. Heck, you
                          might not even know what Freshservice is and found
                          yourself on this blog looking for a good cloud-based
                          IT help desk and service management solution. Whatever
                          the reason might be you are here for{" "}
                        </p>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2">
                        {/* Section 1 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 market_wrapper_page line-height-2"
                          id="section1"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            What is Freshservice Chatbot?
                          </h2>
                          <p class="font-section-normal-text line-height-2 mb-3">
                            You can simplify your business’ IT operations with
                            the ticketing system, self-service portal, and
                            knowledge-base that IT help desk and service
                            management solutions that Freshservice provides, but
                            you can make your IT operations even more simpler
                            and faster with AI-powered contextual chatbots with
                            automated workflows that enable 24×7 conversational
                            self-service for your employees on your business’
                            communications hub like&nbsp;
                            <a href="https://workativ.com/conversational-ai-platform/slack-chatbot-guide">
                              Slack
                            </a>
                             or 
                            <a href="https://workativ.com/conversational-ai-platform/microsoft-teams-vs-slack">
                              &nbsp;Microsoft Teams.
                            </a>
                          </p>
                        </div>

                        {/* Section 2 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section2"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How does Freshservice Chatbot works? 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0">
                            Chatbots analyze and process user words and
                            expressions and provide an instant pre-programmed
                            response.{" "}
                            <a href="https://workativ.com/conversational-ai-platform/freshservice-chatbot">
                              Freshservice Chatbots
                            </a>{" "}
                            understand natural language, but they also follow a
                            predetermined path for initiating a conversation as
                            well as extracting required data from a user’s query
                            and passing it to the automation to resolve the
                            user’s issue in real-time. The objective of NLP is
                            to make computer-human connections seem like a
                            dialogue between two individuals. People can freely
                            communicate with chatbots by asking questions using
                            NLP. They can recall the conversation's background
                            as well as the user's choices. When necessary, these
                            chatbots can bounce from one point of discussion
                            situation to another and respond to random user
                            requests seamlessly. So, think of how intelligent
                            chatbots work, but with Freshservice chatbot, it can
                            not only have those benefits but also extend the
                            functionalities of the Freshservice tool using
                            advanced integration and workflows.
                          </p>
                        </div>

                        {/* Section 3 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-0 market_wrapper_page"
                          id="section3"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            What is Freshservice chatbot used for?
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-3">
                            Chatbots boost ITSM efficiency and productivity by
                            quickly resolving common employee issues. Automation
                            is a top business priority for IT leaders today, and
                            chatbots are a next-generation automation tool for
                            getting more done with less. Chatbots divert
                            insignificant tickets away from human agents. When
                            chatbots are being used for IT support, chatbots
                            interact with users through a conversational UI to
                            solve problems faster and to be available 24 hours a
                            day, seven days a week. Chatbots are designed to
                            learn from previous interactions and evolve over
                            time.
                          </p>
                          <p class="font-section-normal-text line-height-2 mb-3">
                            A Freshservice chatbot can be quite helpful not just
                            for your users but also for the agents as it helps
                            automate repetitive tasks so that your team can
                            focus on more important and complex tasks that
                            require a human touch.{" "}
                          </p>
                          <p class="font-section-normal-text line-height-2 mb-3">
                            We have compiled a list of the most repetitive tasks
                            that you can automate using Freshservice chatbot: 
                          </p>
                          <ul
                            class="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li> Creating a Freshservice ticket</li>
                            <li> Deleting a Freshservice ticket</li>
                            <li>
                              Retrieving details about a Freshservice ticket
                            </li>
                            <li> Updating a Freshservice ticket </li>
                            <li> Adding/Removing a requestor</li>
                            <li> Updating details about a requestor</li>
                            <li>
                               Faster user provisioning and de-provisioning {" "}
                            </li>
                            <li> Password reset</li>
                            <li>Ticket classification and allocation</li>
                          </ul>
                        </div>
                        {/* Section 4 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section4"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Best Freshservice Chatbot platform
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-3">
                            Workativ Assistant - no code platform with both
                            chatbot and workflow automation tools.
                          </p>
                        </div>
                        <NocodeWrapper />

                        {/* Section 5 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section5"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Smarter AI-powered Freshservice automations with
                            Freshservice Chatbot
                          </h2>

                          <p className="font-section-normal-text line-height-2  mb-3">
                            By integrating Freshservice with Workativ Assistant,
                            your employees will be able to do the following
                            using Workativ Assistant’s chatbot on your business’
                            Slack or Microsoft Teams workspace: 
                          </p>

                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Creating a Freshservice Ticket 
                            </span>
                            — By telling Workativ Assistant’s 
                            <a href="https://workativ.com/conversational-ai-platform/freshservice-chatbot">
                              Freshservice chatbot
                            </a>
                             to create a Freshservice ticket for an issue they
                            are facing, Workativ Assistant’s chatbot instantly
                            creates the ticket for employees in just seconds. 
                          </p>
                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Deleting a Freshservice Ticket
                            </span>
                            — No more logging into the Freshservice portal every
                            time an employee resolves an issue themselves and
                            wants to delete the Freshservice ticket they raised
                            for the
                            issue. Workativ Assistant’s Freshservice chatbot takes
                            care of this in the blink of an eye
                          </p>
                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Retrieving details about a Freshservice Ticket
                            </span>
                            — Whenever an employee wants to know the status of
                            their ticket, they’ll just have to slide open their
                            mobile phone, open their Slack or Microsoft Teams
                            app, have a quick chat
                            with Workativ Assistant’s Freshservice chatbot there
                            and that’s it. 
                          </p>
                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Updating a Freshservice Ticket
                            </span>
                            — Help desk agents can make use
                            of Workativ Assistant’s Freshservice chatbot by
                            telling it to update a Freshservice ticket’s status
                            from the comfort of their business’ chat hub. 
                          </p>
                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Adding/Removing a Requestor to&nbsp; Freshservice 
                            </span>
                            — With Workativ Assistant’s Freshservice chatbot, IT
                            Admins can add or remove employees
                            in Freshservice as and when they onboard/offboard.  
                          </p>
                          <p className="font-section-normal-text line-height-2  mb-3">
                            <span className="font-section-normal-text-medium">
                              Updating details about a Requestor
                              in&nbsp; Freshservice 
                            </span>
                            — Workativ Assistant’s Freshservice chatbot also
                            enables IT Admins to update details about an
                            employee in Freshservice in a flash.  And there you
                            go, all the Freshservice tasks you can automate
                            with Workativ Assistant. And it doesn’t stop there. 
                          </p>
                        </div>

                        {/* Section 6 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 mb-0 market_wrapper_page"
                          id="section6"
                        >
                          <h2 className="font-section-sub-header-small-bold">
                            Business benefits of&nbsp; Freshservice
                            chatbot&nbsp; with Workativ Assistant
                          </h2>
                          <h3 className="font-section-normal-text-medium mb-0">
                            Workativ Assistant’s Chatbots vs Other Chatbots
                          </h3>

                          <p className="font-section-normal-text line-height-2 mb-0">
                            Service Desk tools like Freshservice provide their
                            own chatbot (Servicebot) on Microsoft Teams that
                            takes care of some of the tasks on Freshservice (but
                            not all). The tasks include: 
                          </p>
                          <ul
                            className="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              Allow the use of built-in commands to fetch,
                              filter, and track specific incidents and service
                              requests. {" "}
                            </li>
                            <li>
                              Allow an agent to perform quick actions on an
                              assigned ticket like sending a reply, adding a
                              private/public note, right from their Microsoft
                              Teams account. {" "}
                            </li>
                          </ul>
                          <p className="font-section-normal-text line-height-2 mb-0">
                            And these are just for IT
                            agents. Workativ Assistant’s Freshservice chatbot,
                            on the other hand, will let your employees as well
                            as IT agents to: 
                          </p>
                          <ul
                            className="font-section-normal-text"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              Create/update/retrieve/delete a ticket/software
                              request/hardware request in just minutes. (For
                              employees) 
                            </li>
                            <li>
                              Add/update/retrieve/remove a requestor
                              effortlessly. (For IT agents)  Not only these,
                              but 
                              <a href="https://workativ.com/conversational-ai-platform/marketplace">
                                &nbsp;you can do so much more
                                with Workativ Assistant’s chatbots. 
                              </a>
                            </li>
                          </ul>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Let’s take a look at all the scenarios for which you
                            can use Workativ Assistant’s no-code platform to
                            <a href="https://workativ.com/conversational-ai-platform">
                              &nbsp;build chatbots
                            </a>{" "}
                            for. 
                          </p>
                          <h2 className="font-section-sub-header-small-bold">
                            Make your IT Service Management (ITSM) processes
                            faster
                          </h2>

                          <p className="font-section-normal-text line-height-2 mb-3">
                            By offering the services of your Service Desk tool
                            like 
                            <a href="https://blog.workativ.com/freshservice-integrations-automate-business-workflows-with-workativ-assistant/">
                              &nbsp;Freshservice in the form of a conversation
                              to your employees via chatbots,
                            </a>{" "}
                            you ensure that your employees get the IT support
                            experience they’d love. Be it for requesting a
                            software/hardware or IT issues like password resets
                            or account unlocks, your employees can do all this
                            and more with Workativ Assistant’s chatbots. {" "}
                          </p>
                          <ExistingBlogCta
                            ContentCta="Auto-resolve 60% of FreshService Employee Queries."
                            ButtonText="Book a Demo"
                            isColor="white"
                            backgroundImage={cta_2}
                            mobileBackgroundImage={cta_2_mob}
                          />
                          <h5 className="font-section-normal-text-medium">
                            1. IT Service Request
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Sometimes employees may find that their
                            laptop/desktop doesn’t have the right
                            software/hardware provision to perform a certain
                            task. Be it a RAM upgrade for their CPU or
                            requesting software/software updates, employees have
                            to log in and out of their company’s Service Desk
                            portal (like Freshservice) every time they want
                            anything. Employees also have to keep track of their
                            service request via the portal. This adds on to the
                            frustration of the employee not being able to
                            perform the task at hand. Not anymore.  By building
                            a chatbot for this on Workativ Assistant and adding
                            it to your business’ Microsoft Teams channel, you
                            ensure your employees can easily create a service
                            request as well as track it from the comfort of
                            their chat channel. And building
                            a Workativ Assistant chatbot is not so hard either.
                            Set up the automation and the conversation flow you
                            want for a chatbot in three very simple steps, test
                            the chatbot, add it to your Microsoft Teams channel,
                            and you’re done. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            2. IT issues 
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Let’s say you’re using Freshservice for ITSM and
                            other apps like Microsoft Azure Active Directory,
                            Okta, JumpCloud, OneLogin, or Auth0 for Identity and
                            Access Management (IAM). And you want to make your
                            ITSM app work with your IAM app. Not possible,
                            right? You might find a way to make both of them
                            work together but maybe not in the exact way you had
                            imagined. So how to make different apps work
                            synergistically together?
                          </p>
                          <p className="font-section-normal-text mb-3">
                            Enter Workativ Assistant’s chatbots.
                            With Workativ Assistant’s chatbots, you can
                            automate: 
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            <span className="font-section-normal-text-medium">
                              a. Reset Passwords
                            </span>
                            — Easily build{" "}
                            <a href="https://workativ.com/use-cases/reset-password-automation">
                              chatbots that reset passwords for your employees
                            </a>{" "}
                            by connecting your IAM app like Microsoft Azure
                            Active Directory (AD) and setting up a password
                            reset automation for it. You can even set it up by
                            connecting your Freshservice account along with
                            Microsoft Azure Active directory such that, when the
                            AD password reset automation encounters a hiccup,
                            the Workativ Assistant chatbot will automatically
                            create a ticket in Freshservice and alert your help
                            desk team about the issue.   
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            <span className="font-section-normal-text-medium">
                              b. Account Unlocks
                            </span>
                            — 
                            <a href="https://workativ.com/use-cases/unlock-account-automation">
                              Make unlocking/unsuspending accounts
                              effortless&nbsp;
                            </a>
                            for your employees. Just connect your IAM app like
                            Active Directory with Workativ Assistant, set up the
                            unlock/unsuspend automation for it, and the
                            conversation flow for that automation that the
                            chatbot can use for conversing with your employees,
                            and you’re done.  You can even add an extra action
                            to the automation for creating a ticket
                            in Freshservice in case the unlock/unsuspend
                            automation goes haywire.  
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            <span className="font-section-normal-text-medium">
                              c. Knowledgebase/FAQs
                            </span>
                            — Connect your G Suite, Dropbox or Box account
                            with Workativ Assistant and let your employees
                            access your Knowledgebase via Workativ Assistant’s
                            chatbots in the blink of an eye. You can set it up
                            the automation like, if the chatbot isn’t able to
                            find a file on your G Suite, it’ll continue
                            searching in your other apps like Dropbox or Box.  
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            <span className="font-section-normal-text-medium">
                              d. Create Distribution Groups
                            </span>
                            — By connecting Workativ Assistant with your Office
                            365 account (if you have one), you can let your help
                            desk agents as well as your HR personnel create an
                            email distribution group from the comfort of their
                            chat hub. To ensure this, you can even go a step
                            further and add an action for creating a support
                            ticket in Freshservice in case the creating a
                            distribution list automation encounters an hiccup. 
                            Now that we’ve seen the ITSM side of things, let’s
                            take a look at what all Workativ Assistant can do
                            for your HR team.   
                          </p>
                          <h2 className="font-section-sub-header-small-bold">
                            Streamline HR processes with Conversational AI 
                          </h2>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Why stop with IT process automation when you can
                            make it easier on your HR team with HR chatbots?{" "}
                            <a href="https://blog.workativ.com/integrate-and-automate-gusto-workflows-with-any-3rd-party-software-workativ/">
                              Connect Workativ Assistant with your HR apps
                            </a>{" "}
                            and let Workativ Assistant’s HR chatbots do all the
                            heavy lifting for you: {" "}
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            1. Let employees update their info themselves 
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            You might’ve come across employees shifting to
                            another place, changing their phone numbers, getting
                            married, or even becoming a new parent. To record
                            these changes in their company profile, employees
                            have to go through the tedious process of logging in
                            to their self-service portal, navigating through
                            labyrinthine menus, creating/raising a request, and
                            then waiting, hoping that someone notices that
                            request. Whew, sounds exhausting just reading it
                            right? Think about your employees and how they would
                            feel. 
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            To get rid of this frustrating wait time for your
                            employees, you can simply choose to make use
                            of Workativ Assistant’s chatbots.
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            By connecting your Twilio and IAM app like Microsoft
                            Active Directory with Workativ Assistant, you can
                            set up an automation such that, after verifying the
                            Twilio OTP with your employee, Workativ Assistant’s
                            chatbot will update their company profile for them
                            after getting the required details from your
                            employees. You can even add an action for creating a
                            ticket in Freshservice in case things go haywire
                            with the updating info automation. {" "}
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            2. Employee Onboarding/Offboarding   
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Take the load off your HR personnel and let them
                            focus on more important tasks like candidate
                            screening and employee welfare by automating tasks
                            like employee onboarding and offboarding
                            with Workativ Assistant. It’s simple. Just connect
                            your IAM app (like Microsoft Azure Active Directory)
                            with Workativ Assistant, set up an automation for
                            adding or removing details about an employee on AD,
                            set up a conversation flow that the chatbot can use
                            for it, and you’re done. You can even add an extra
                            step to the automation by connecting
                            your Freshservice account and setting up the
                            creating a Freshservice ticket action in case things
                            go messy with the AD automation. After you set up
                            the HR chatbot, your HR personnel can interact with
                            it on their Microsoft Teams workspace and get the
                            onboarding/offboarding done on-the-go, in a jiff.
                            This hiccup-free{" "}
                            <a href="https://workativ.com/use-cases/employee-onboarding-automation">
                              onboarding experience
                            </a>{" "}
                            ensures that new employees stick around and become
                            brand ambassadors for your company.
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            3. Applying Leaves   
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Applying for leaves should never be a hassle for
                            your employees. Normally, employees may have to ask
                            their reporting person if they can take a day out
                            for a personal reason and if that person approves,
                            the employee will have to email that person with
                            their manager and HR in CC. After seeing the email,
                            the manager and HR may or may not approve the leave
                            based on their previous leave history. What a
                            tedious way to handle the leave process, right?
                            Everything should just be done with the click of a
                            button. And that’s exactly what Workativ Assistant’s
                            HR chatbots lets your employees as well as your HR
                            personnel do. 
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            By connecting your HR apps like Zenefits, BambooHR,
                            Deputy, or PeopleHR with Workativ Assistant, you can{" "}
                            <a href="https://workativ.com/use-cases/leave-management-automation">
                              set up an automation for employees to apply leaves{" "}
                            </a>
                            via 
                            <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                              Workativ Assistant’s HR chatbots.
                            </a>{" "}
                            You can set up an automation like, if the action for
                            applying for a leave encounters a bump, the HR
                            chatbot will create a ticket in Freshservice,
                            alerting your help desk team about the issue so that
                            they can immediately step in and take
                            over. Employees can then apply for a leave
                            via Workativ Assistant’s HR chatbot on Microsoft
                            Teams which will then inform the respective
                            personnel about the leave and if they approve by
                            clicking on a button, the employee gets the leave.
                            Simple, right? {" "}
                          </p>
                          <h5 className="font-section-normal-text-medium mb-0">
                            4. General enquires    
                          </h5>

                          <p className="font-section-normal-text line-height-2 mb-3">
                            Instead of having to visit the HR in-person or call
                            them up, let employees know their employment details
                            like salary structure, payroll, workplace policy,
                            and benefits by integrating your HR apps like
                            Zenefits, BambooHR, Deputy, or PeopleHR
                            with Workativ Assistant. Connect your HR app like
                            Zenefits with Workativ Assistant, set up an
                            automation to answer queries like employee benefits,
                            throw in another action to the automation to create
                            a ticket in Freshservice if the HR queries
                            automation goes bonkers, set up a conversation flow
                            for the chatbot for the automation, add the chatbot
                            to your Microsoft Teams channel and you’re set.
                            Employees can then just bring up the HR chatbot on
                            their Microsoft Teams desktop/mobile app anytime
                            they want to{" "}
                            <a href="https://workativ.com/use-cases/payroll-enquiry-automation">
                              know their payroll details
                            </a>{" "}
                            in a jiff. 
                          </p>
                          <h2 className="font-section-sub-header-small-bold mb-3">
                            The value that you’d get with Workativ Assistant 
                          </h2>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Now that you know the ways in
                            which Workativ Assistant is beneficial to you, let
                            take a step further and see how Workativ Assistant
                            adds value to your workplace support. 
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            1. Seamless, on-the-go, 24×7 self-service 
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            According to a 
                            <a href="https://www.statista.com/statistics/1033742/worldwide-microsoft-teams-daily-and-monthly-users/#:~:text=Microsoft%20Teams%3A%20number%20of%20daily%20active%20users%202019%20and%202020&text=The%20number%20of%20daily%20active,2020.">
                              &nbsp;report&nbsp;
                            </a>
                             by Statista, the number of daily active users of
                            Microsoft Teams has more than doubled in recent
                            months, increasing from 32 million users on March
                            2019 to 75 million on April 2020. This means that
                            your employees are more than likely to use their
                            business’ Microsoft Teams workspace to self-serve
                            their IT/HR needs themselves instead of logging in
                            to a self-service portal and raising a ticket for
                            everything. 
                          </p>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            By integrating your ITSM tool like Freshservice or
                            HR app like Zenefits with Workativ Assistant and
                            adding Workativ Assistant’s chatbots to your
                            Microsoft Teams workspace, you ensure that your
                            employees are satisfied with the two-way
                            conversational self-service they are receiving and
                            are more productive in turn. Workativ Assistant’s
                            chatbots are available to your employees 24×7,
                            on-the-go, on their laptop/mobile phone. {" "}
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            2. No coding required for setup
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            <a href="https://workativ.com/conversational-ai-platform/nocode-chatbot">
                              Setting up automations and conversations for a
                              chatbot on Workativ Assistant is effortless&nbsp;
                            </a>
                             to be honest. You don’t have to get a developer on
                            board to set up a Workativ Assistant chatbot. If a
                            five-year-old spends a little time
                            with Workativ Assistant, she/he can build a chatbot
                            in a flash. Workativ Assistant is designed that way,
                            to be usable by anyone with no coding knowledge. 
                          </p>
                          <h5 className="font-section-normal-text-medium">
                            3. Intuitive UI 
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-0">
                            Workativ Assistant incorporates the best of both
                            worlds — both creating an automation as well a
                            conversation flow for that automation. For
                            this, Workativ Assistant’s Automation Designer and
                            Dialog Designer work seamlessly with your Microsoft
                            Teams apps to create the conversational AI
                            experience that your employees would
                            love. Workativ Assistant leverages NLP to understand
                            your employees better and to deliver faster,
                            empathetic IT/HR support that your employees would
                            appreciate. 
                          </p>

                          <h5 className="font-section-normal-text-medium">
                            4. Less time for onboarding 
                          </h5>
                          <p className="font-section-normal-text line-height-2 mb-3">
                            Given the fact that Workativ Assistant seamlessly
                            integrates with your business’ existing apps, and
                            the fact that it’s easy to set up,
                            making Workativ Assistant a part of your IT Service
                            Management/HR Management is fairly simple and takes
                            no time. Just sign up for a Workativ Assistant
                            account, connect the apps you use frequently, and
                            provide an awesome self-service experience for your
                            employees. 
                          </p>
                        </div>

                        {/* Section 7 */}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section7"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            How to Build Freshservice Chatbot Without coding
                          </h3>
                          <h3 className="font-section-normal-text-medium">
                            Step 1. Creating a bot workspace 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            When you sign up for Workativ Assistant, you’ll be
                            required to name your workspace. You can provide the
                            name of  your organization as a workspace name. 
                          </p>
                          <img
                            loading="lazy"
                            src={image1}
                            className="mb-5"
                          ></img>
                          <h3 className="font-section-normal-text-medium">
                            Step 2. Download your bot  
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Download a prebuilt Freshservice bot from the bot
                            Marketplace   
                          </p>
                          <img
                            loading="lazy"
                            src={image2}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image3}
                            className="mb-5"
                          ></img>
                          <p className="font-section-normal-text line-height-2">
                            So that’s all there is to the initial set up of your
                            workspace. Next, let’s download Freshservice
                            workflows.
                          </p>
                          <h3 className="font-section-normal-text-medium">
                            Step 3. Setting up a Freshservice app workflow  
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-1 pb-0">
                            Lets download Freshservice app workflow from
                            marketplace   
                          </p>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Click on Download from Marketplace   
                          </p>
                          <img
                            loading="lazy"
                            src={image4}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the Freshservice application   
                          </p>
                          <img
                            loading="lazy"
                            src={image5}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the app workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image6}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Connect with your Freshservice account   
                          </p>
                          <img
                            loading="lazy"
                            src={image7}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image8}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image9}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test the app workflow   
                          </p>
                          <img
                            loading="lazy"
                            src={image10}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium">
                            Step 4: Now add workflow to the Freshservice
                            chatbot 
                          </h3>
                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Add app workflow to the dialog conversation  
                          </p>
                          <img
                            loading="lazy"
                            src={image11}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Select the workflow  
                          </p>
                          <img
                            loading="lazy"
                            src={image12}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Provide the inputs & save it   
                          </p>
                          <img
                            loading="lazy"
                            src={image13}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image14}
                            className="mb-5"
                          ></img>

                          <p class="font-section-normal-text line-height-2 mb-0 pb-0">
                            Test your Freshservice chatbot 
                          </p>
                          <img
                            loading="lazy"
                            src={image15}
                            className="mb-5"
                          ></img>

                          <h3 className="font-section-normal-text-medium mb-0 pb-0">
                            Step 5: Deploy Freshservice chatbot in your
                            favourite chat channel   
                          </h3>
                          <img
                            loading="lazy"
                            src={image16}
                            className="mb-4"
                          ></img>
                          <img
                            loading="lazy"
                            src={image17}
                            className="mb-0"
                          ></img>
                        </div>

                        {/* video section*/}

                        <div className="build_needs">
                          <div class="col-lg-12 col-md-12 col-12 pl-0 center_features_header">
                            <h2 className="font-section-header mb-3">
                              Steps to build Freshservice Chatbot with workflow
                              automation
                            </h2>
                          </div>
                        </div>

                        <VideoBlog
                          videoURL={
                            "https://www.youtube-nocookie.com/embed/VVUnw_Ne5ek"
                          }
                          imgURL={VidImg}
                        />
                        {/* Section 8*/}
                        <div
                          className="col-lg-12 col-md-12 col-sm-12 pl-0 col-xs-12 market_wrapper_page"
                          id="section8"
                        >
                          <h3 className="font-section-sub-header-small-bold">
                            Conclusion  
                          </h3>
                          <p class="font-section-normal-text line-height-2">
                            In conclusion, Freshservice chatbots are a great way
                            to keep customers engaged and provide the best
                            possible experience. With the right tools and
                            strategy, your business can see a significant
                            increase in customer satisfaction. They can interact
                            with customers by sending them messages, reminders,
                            and important information. Chatbots can also be used
                            to automate mundane tasks and free up your human
                            agents to do more complex tasks while making the
                            entire process effective, effortless, and efficient.
                          </p>
                          <p class="font-section-normal-text line-height-2">
                            Haven’t tried Workativ Assistant yet? Then you’re
                            missing out on a lot.{" "}
                            <a href="https://workativ.com/conversational-ai-platform/freshservice-chatbot">
                              &nbsp;Try our Freshservice chatbot for FREE now.
                            </a>
                          </p>
                        </div>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot

                        "
                              >
                                A Guide to Conversational AI Chatbots
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/chatbot-vs-conversational-ai"
                              >
                                Chatbots vs Conversational AI
                              </a>
                            </li>
                            <li>
                              <a
                                className="blog_usecase_link font-section-normal-text"
                                href="https://workativ.com/conversational-ai-platform/chatbot-best-practices-for-designing-a-conversational-ai"
                              >
                                Chatbot Best Practices for designing a
                                Conversational Experience with Workativ
                                Assistant
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper margin-no-code mb-5">
      <h4>No Code - Free Freshservice Chatbot</h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="freshservice chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
